<template>
  <div class="p-role-edit">
    <div class="m-grid-search" style="margin-bottom: 10px">
      <el-button class="m-grid-btn" type="primary" @click="onSave" :disabled="isSaveShow">保存角色</el-button>
    </div>
    <section class="p-list-search">
      <div class="info-one">
        <znl-input :border="true"
                   width="330px"
                   title="角色名称:"
                   :clearable="true"
                   :height="32"
                   type="text"
                   v-model="name"
                   title-width="60px"
                   style="margin-right: 20px;"
                   placeholder="">
        </znl-input>
        <p class="inline-block" style="width: 180px; display: inline-block;">
          <span style="display: inline-block;margin: 0 10px">是否启用:</span>
          <el-checkbox v-model="isEnabled">启用这个角色</el-checkbox>
        </p>
      </div>
      <div class="info-one">
        <znl-input :border="true"
                   width="330px"
                   title="识别码:"
                   :clearable="true"
                   :height="32"
                   type="text"
                   class="vat"
                   title-width="60px"
                   v-model="code"
                   style="margin-right: 20px;"
                   placeholder="">
        </znl-input>
        <znl-input :border="true"
                   width="500px"
                   title="备注:"
                   :clearable="true"
                   :height="32"
                   type="textarea"
                   title-width="60px"
                   v-model="description"
                   style="margin-right: 20px;"
                   placeholder="">
        </znl-input>
      </div>
    </section>
    <section class="footer">
      <p style="line-height: 40px;font-size: 14px;">权限设置：</p>
      <table class="c-table-box" style="width: 95%">
        <thead>
        <th width="20%">菜单权限</th>
        <th width="80%">操作权限</th>
        </thead>
        <tbody>
        <tr v-for="(item,index) in roleData" :key="index">
          <td>
            <span style="display: inline-block;width: 60%; text-align: left">{{item.Name}}</span>
          </td>
          <td style="text-align: left ;padding-left: 10px">
            <el-checkbox v-for="(smallItem,index) in item.children" :key="index" v-model="smallItem.IsEnabled">
              {{smallItem.Name}}
            </el-checkbox>
          </td>
        </tr>
        </tbody>
      </table>
    </section>
  </div>
</template>
<script>
import {permissionTreeify} from './utils';
import { GetEdit, SaveRole } from '@/api/role';
import store from "@/store";
const CONFIG = {
  New: 'Permission/GetAvailableList',
  Get: 'Role/Get',
  Add: 'Role/Add',
  Update: 'Role/Update'
}
export default {
  data () {
    return {
      id: '',
      roleData: [],
      name: '',
      isEnableName: true,
      description: '',
      isEnabled: false,
      code: '',
      isSaveShow: false
    }
  },
  methods: {
    // 获取初始数据
    async getData () {
      const { id } = this.$route.query
      this.id = id;
      const data = await GetEdit({ id })
      this.name = data.Name || ''
      this.description = data.Description || ''
      this.isEnabled = data.IsEnabled || ''
      this.code = data.Code || ''
      const newData = data.Permissions || []
      const pages = permissionTreeify(newData)
      this.roleData = pages
    },
    // 保存
    async onSave () {
      let permissions = this.setData()
      let params = {
        id: this.id,
        Name: this.name,
        Code: this.code,
        Description: this.description,
        IsEnabled: this.isEnabled,
        Permissions: permissions
      }
      this.isSaveShow = true
      try {
        await SaveRole(params)
        this.$message({
          message: '操作成功,即将跳转角色管理列表页',
          type: 'success'
        })
        await this.$store.dispatch('tagsView/delView', this.$route)
        this.$router.push('role')
      } catch (e) {
        throw e
      }finally {
        this.isSaveShow = false
      }
    },
    // 处理数据
    setData () {
      const data = this.roleData
      let dataArr = []
      _.each(data, item => {
        dataArr = dataArr.concat(item.children)
      })
      const permissions = _.map(dataArr, item => ({Id: item.Id, IsEnabled: item.IsEnabled}))
      return permissions
    }
  },
  async created () {
    await this.getData()
  }
}
</script>
<style lang="scss">
.p-role-edit {
  padding: 10px;
  .m-grid-search {
    height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 6px 20px 6px 10px;
    background: #d7d7d7;
    margin-right: 15px;
    text-align: right;

  }
  .info-one {
    margin: 20px 0;
    display: flex;
  }
  .c-table-box thead th {
    line-height: 30px;
    font-size: 12px;
  }
  .c-table-box thead {
    background-color: #999;
    color: #fff;
  }
  .c-table-box {
    border-collapse: collapse;
    width: 70%;
    display: table;
    box-sizing: border-box;
    border-spacing: 2px;
    border-color: grey;
  }
  .c-table-box tr {
    position: relative;
    border-bottom: 1px solid #e6e6e6;
  }
  .c-table-box {
    tr {
      td {
        text-align: center;
        font-size: 12px;
      }
    }
  }
  .c-table-box tr>td {
    border: 1px solid #e6e6e6;
    line-height: 26px;
  }
  td:first-child {
    .el-checkbox {
      text-align: left;
      width: 80%;
    }
  }
  .el-checkbox, .el-checkbox__label {
    font-size: 12px;
  }
}
</style>
