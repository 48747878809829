export function permissionTreeify(permisions) {
	const mapTo = p => ({
		Id: p.Id,
		Name: p.Name,
		Position: p.Position,
		IsEnabled: p.IsEnabled
	})
	const pageMap = permisions.reduce((map, p) => {
		let groupd = map[p.PageName]
		if (groupd) {
			groupd.children.push(mapTo(p))
		} else {
			groupd = {
				Name: p.PageName,
				Position: p.PagePosition,
				children: [mapTo(p)]
			}
			map[p.PageName] = groupd
		}

		return map
	}, {})

	const pages = Object.keys(pageMap)
		.map(key => {
			const page = pageMap[key]
			page.children = _.sortBy(page.children, p => p.Position)
			return page
		})

	const sortedPages = _.sortBy(pages, p => p.Position)
	return sortedPages
}
