import request from '@/utils/request'

export function GetList(data) {
	return request({
		url: '/Role/GetList',
		method: 'post',
		data
	})
}

export function SetEnabled(data) {
	return request({
		url: '/Role/SetEnabled',
		method: 'post',
		data
	})
}

export async function GetEdit(data) {
	const { id } = data
	if (!id) {
		return { Permissions: await GetAvailableList(data) }
	}
	return GetRole(data)
}

export function GetAvailableList(data) {
	return request({
		url: '/Permission/GetAvailableList',
		method: 'post',
		data
	})
}

export function GetRole(data) {
	return request({
		url: '/Role/Get',
		method: 'post',
		data
	})
}

export function SaveRole(data) {
	const { id } = data;
	if (!id) {
	 return AddRole(data)
	} else {
	 return UpdateRole(data)
	}
}

export function AddRole(data) {
	return request({
		url: '/Role/Add',
		method: 'post',
		data
	})
}

export function UpdateRole(data) {
	return request({
		url: '/Role/Update',
		method: 'post',
		data
	})
}

